.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ReactTable
{
  overflow: initial !important;
  min-width: fit-content;
}

.rt-table
{
  overflow: initial !important;
}

/* width */
::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border: white !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border: white !important;
  border-radius: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDialog-paperFullScreen {
  background-color: #000000 !important;
}
